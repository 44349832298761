import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 获取微信小程序链接
export function getWxLink(data) {
  return request({
    url: `${serivceBase.RIS}/wxMaApi/createLink`,
    method: 'post',
    data
  })
}
